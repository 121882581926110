import React, {memo} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_BACKEND_CONFIG } from '../../commonQueries';

const CertifiedShop = () => {
    const { data } = useQuery(GET_BACKEND_CONFIG);
    const { smind_isActive, smind_version, smind_key } = data && data.configProvider || {};

    if (typeof document !== 'undefined' && smind_isActive === "1") {
        let smdWrapper = document.createElement("script"), smdScript;
        smdWrapper.async = true;
        smdWrapper.type = "text/javascript";
        smdWrapper.src = "https://cpx.smind.hr/Log/LogData?data=" + JSON.stringify({
            Key: smind_key,
            Type: "badge",
            Version: smind_version,
            BadgeClassName: "smdWrapperTag"
        });
        smdScript = document.getElementsByTagName("script")[0];
        smdScript.parentNode.insertBefore(smdWrapper, smdScript);
    }

    return (<div className={"smdWrapperTag"}/>);

}

export default memo(CertifiedShop);