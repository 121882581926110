import React, {useEffect, useState} from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import ScrollToTopImage from '../../assets/icons/footer-icon-scroll-to-top.png';
import MobilePhone from '../../assets/icons/mobile_phone.svg';
import CertifiedShop from './CertifiedShop';
import './Footer.scss';
import readConfig from '../../utils/readClientConfiguration';
import productionConfiguration from '../../../config/default.json';

const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');

const FETCH_FOOTER = gql`
  query getCmsBlocks($identifiers: [String]) {
    cmsHydratedBlocks(identifiers: $identifiers) {
      items {
        title
        identifier
        hydrated_content
      }
    }
  }
`;

function replaceHTMLNodesWithReact(domNode) {
  try {
    const { name, attribs, children } = domNode;
    if (name === 'div' && attribs.class === 'smdWrapperTag') {
      return <CertifiedShop />;
    }
    if (name === 'a' && attribs.target !== '_blank') {
      if (attribs.href && attribs.href.startsWith('tel:')) {
        return;
      }

      // Extract the pathname - might not work on SSR
      const { pathname } = new URL(attribs.href);
      const processedLink = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
      return <Link to={processedLink}>{domToReact(children)}</Link>;
    }
  } catch (e) {}

  return null;
}

function Footer() {
  const [isOnTop, setIsOnTop] = useState(true);
  const { data, loading } = useQuery(FETCH_FOOTER, {
    variables: {
      identifiers: ['footer_block'],
    },
    ssr: true,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    window.onscroll = () => window.pageYOffset === 0 ? setIsOnTop(true) : setIsOnTop(false);
    return () => (window.onscroll = null);
  });

  if (loading) {
    return <></>;
  }

  const [block] = data.cmsHydratedBlocks.items;

  const footerContent = block.hydrated_content.replace(/#html-body/g, 'body');

  const scrollToTopHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {!isOnTop && <div id="mobile-phone">
        <a role="navigation" href={`tel:${phoneSupportNumber}`}>
          <img loading="lazy" alt="mobile-phone" src={MobilePhone} width={50} height={50}/>
        </a>
      </div>}
      {!isOnTop && <div id="scroll-to-top">
        <a role="navigation" onClick={scrollToTopHandler}>
            <img
              loading="lazy"
              alt="scroll-to-top-button"
              id="scroll-to-top-button"
              src={ScrollToTopImage}
              width={50}
              height={50}
            />
        </a>
      </div>}

      <footer className="page-footer">
        {parse(`<div class="footer-container">${footerContent}</div>`, {
          replace: replaceHTMLNodesWithReact,
        })}
      </footer>
    </>
  );
}

export default React.memo(Footer);
